<script src="https://cdn.tailwindcss.com"></script>
<template>
  <div class="content">
    <v-card>
      <v-tabs v-model="currentTabIndex" background-color="#e2007a" dark>
        <v-tab v-for="tab in tabs" :key="tab" :value="tab">{{ tab }}</v-tab>
      </v-tabs>
    </v-card>

    <Segur v-if="currentTabIndex === 0"></Segur>
    <SegurBon v-if="currentTabIndex === 1"></SegurBon>
    <Segurasp v-if="currentTabIndex === 2"></Segurasp>
  </div>
</template>

<script>
import Vue from "vue";
import SegurBon from "./Segurbon.vue";
import Segur from "./Segur.vue";
import Segurasp from "./Segurasp.vue";

export default {
  name: "Segurmain",
  components: {
    SegurBon,
    Segur,
    Segurasp,
  },
  data: function () {
    return {
      tabs: ["Segur Liste", "Segur Création", "Segur ASP"],
      currentTabIndex: 0,
    };
  },
  created: function () {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
