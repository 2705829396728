/**
 * @Author: Florian Merel <florian>
 * @Date:   02-Nov-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: index.js
 * @Last modified by:   florian
 * @Last modified time: 22-Sep-2021
 */

import Vue from "vue";
import Vuex from "vuex";
import authentification from "./modules/authentification.js";
import usersList from "./modules/usersList.js";
import user from "./modules/user.js";
import tickets from "./modules/tickets.js";
import usersUnlock from "./modules/usersUnlock.js";
import usersQuestions from "./modules/usersQuestions.js";
import notification from "./modules/notification.js";
import usersInscription from "./modules/usersInscription.js";
import followup from "./modules/followup.js";
import activity from "./modules/activity.js";
import publicTicket from "./modules/publicTicket.js";
import uploadFiles from "./modules/uploadFiles.js";
import downloadFiles from "./modules/downloadFiles.js";
import licences from "./modules/licences.js";
import server from "./modules/server.js";
import analitics from "./modules/analitics.js";
import admin from "./modules/admin.js";
import providers from "./modules/providers.js";
import statistiques from "./modules/statistiques.js";
import parkManagement from "./modules/parkManagement.js";
import rpps from "./modules/rpps.js";

import ticketsV2Filters from "./modules/TicketsV2/ticketsV2Filters.js";
import ticketsV2Badges from "./modules/TicketsV2/ticketsV2Badges.js";
import ticketsV2Keywords from "./modules/TicketsV2/ticketsV2Keywords.js";
import ticketsV2Tickets from "./modules/TicketsV2/ticketsV2Tickets.js";
import ticketsV2Search from "./modules/TicketsV2/ticketsV2Search.js";
import ticketsV2Notifications from "./modules/TicketsV2/ticketsV2Notifications.js";

import keyup from "./modules/Notifications/keyup.js";
import segur from "./modules/segur.js";
import subscription from "./modules/subscription.js";
import orderform from "./modules/orderform.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentification,
    usersList,
    user,
    tickets,
    usersUnlock,
    usersQuestions,
    notification,
    usersInscription,
    followup,
    activity,
    publicTicket,
    uploadFiles,
    downloadFiles,
    licences,
    server,
    analitics,
    admin,
    providers,
    statistiques,
    parkManagement,
    rpps,

    ticketsV2Filters,
    ticketsV2Badges,
    ticketsV2Keywords,
    ticketsV2Tickets,
    ticketsV2Search,
    ticketsV2Notifications,

    keyup,

    segur,
    subscription,
    orderform,
  },
});
