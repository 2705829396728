/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 01-Sep-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  orderForms: [],
};

const getters = {
  getOrderForms(context) {
    return context.orderForms;
  },
};

const mutations = {
  UPDATE_ORDER_FORMS(state, payload) {
    state.orderForms = payload;
  },
  UPDATE_LOADING(state, enabled) {
    state.loading = enabled;
  },
};

const actions = {
  async getOrderForms(context) {
    context.commit("UPDATE_LOADING", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.orderforms,
        headers: server.getHeader(),
      });
      context.commit("UPDATE_ORDER_FORMS", res.data.data.orderForms);
    } catch (error) {}
    context.commit("UPDATE_LOADING", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
