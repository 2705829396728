<!-- eslint-disable prettier/prettier -->
<!--
@Author: Jessy DROUIN
@Date:   01-dec-2023
@Email:  jessy.drouin@equasens.com
@Project: CaisseUI
@Filename: Caisse.vue
@Last modified by:   Jessy
@Last modified time: 01-dec-2023
-->

<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-size-100">
                <!-- Bouton de recherche -->
                <md-card style="margin-bottom: 40px; margin-top: 10px">
                    <div class="md-layout">
                        <div class="md-layout-item">
                            <md-field style="">
                                <label>Recherche</label>
                                <md-input v-on:keyup.enter="search" v-model="query"></md-input>
                            </md-field>
                            <button class="diagCloseButton" @click="clearSearch()">
                                <md-icon>delete</md-icon>
                            </button>
                        </div>
                    </div>
                    <md-button class="md-prokov researcheButton" @click="search()">Rechercher</md-button>
                </md-card>

                <!-- Bouton d'ajout de service -->
                <md-card style="margin-bottom: 40px; margin-top: 10px">
                    <div class="md-layout md-alignment-top-left">
                        <div class="md-layout-item">
                            <md-button class="md-prokov addButton" @click="showAddForm = true">Ajouter un
                                service</md-button>
                        </div>
                    </div>
                    <div v-if="showAddForm" class="md-layout">
                        <div class="md-layout-item">
                            <md-field>
                                <label>Code</label>
                                <md-select v-model="newService.code">
                                    <md-option v-for="option in codeOptions" :value="option" :key="option">
                                        {{ option }}
                                    </md-option>
                                </md-select>
                            </md-field>
                            <md-field>
                                <label>Numéro</label>
                                <md-input v-model.trim="newService.numero" @keypress="onlyNumber($event)"
                                    @input="validateNumero"></md-input>
                            </md-field>

                            <!-- Section valeurs -->
                            <v-row class="mt-3">
                                <v-col cols="12">
                                    <v-radio-group v-model="newService.valeur" row>
                                        <v-radio label="Permanent" value="1"></v-radio>
                                        <v-radio label="Inactif" value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" class="mb-3 d-flex align-items-center">
                                    <label class="mr-3 labelStyle">A échéance</label>
                                    <md-datepicker ref="datepicker" class="datePicker"
                                        @input="formatDate"></md-datepicker>
                                </v-col>
                            </v-row>

                            <md-button class="md-prokov" @click="addService()">Ajouter</md-button>
                            <md-button class="md-prokov cancelButton" @click="cancel()">Annuler</md-button>
                        </div>
                    </div>
                </md-card>

                <!-- V-Table d'affichage des données -->
                <h4 style="font-weight: bold;">Services par Caisse</h4>
                <v-data-table :headers="headers" :items="filteredDataArray"
                    :footer-props="{ itemsPerPageOptions: [20, 50, -1] }" class="elevation-1">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-icon small @click="supprimerCaisseData(item)">mdi-delete</v-icon>
                    </template>
                </v-data-table>

                <!-- Dialogue de confirmation pour la suppression -->
                <v-dialog v-model="showDeleteConfirm" persistent max-width="290">
                    <v-card>
                        <v-card-title class="headline">Supprimer l'élément</v-card-title>
                        <v-card-text>Voulez-vous vraiment supprimer cet élément ?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red darken-1" text @click="cancelDelete">Non</v-btn>
                            <v-btn color="green darken-1" text @click="confirmDelete">Oui</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- Dialogue de confirmation pour l'activation d'un service Caisse -->
                <v-dialog v-model="showActivationDialogCaisse" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">Confirmation</v-card-title>
                        <v-card-text>Voulez-vous vraiment activer ce service Caisse ?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="confirmActivationCaisse">Oui</v-btn>
                            <v-btn color="red darken-1" text @click="showActivationDialogCaisse = false">Non</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<!-- eslint-disable prettier/prettier -->
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "edit-profile-services",
    data() {
        return {
            headers: [
                { text: "Code", value: "code" },
                { text: "Numéro de CAISSE/RPPS", value: "numeroCaisse" },
                { text: "Valeur", value: "valeur" },
                { text: "Actions", value: "action", sortable: false },
            ],
            newService: {
                code: '',
                numero: '',
                valeur: '',
            },
            menu: false,
            datePattern: /^\d{2}\/\d{2}\/\d{4}$/,
            codeOptions: ['CERFA', 'SCOR_MEDECIN', 'APCV', 'EPRE', 'EPRE - PRESERIE', 'ROSP'],
            state: "idle",
            itemToDelete: null,
            showAddForm: false,
            showActivationDialogRpps: false,
            showActivationDialogCaisse: false,
            showAddCaisseForm: false,
            query: '',
            filteredDataArray: [],
        }
    },
    async created() {
        await this.fetchAllCaisseData();
        this.search();
    },
    computed: {
        ...mapGetters("rpps", ["caisseData"]),
        showDeleteConfirm() {
            return this.state === "deletingRpps" || this.state === "deletingCaisse";
        },
        caisseDataArray() {
            return Object.entries(this.caisseData).map(([key, value]) => {
                const parts = key.split(' - ');
                if (parts.length < 2) {
                    throw new Error('Invalid key: ' + key);
                }
                const numeroCaisse = parts.pop();
                const code = parts.join(' - ');
                if (code.includes('CAISSE')) {
                    return { code, numeroCaisse, valeur: value };
                }
            }).filter(Boolean); // filter out undefined values
        },
    },
    methods: {
        ...mapActions("rpps", ["fetchAllCaisseData"]),


        //===========================================================================
        // ADD SERVICE
        //===========================================================================
        async addService() {
            this.showActivationDialogCaisse = true;
        },

        //===========================================================================
        // CONFIRMATION ADD SERVICE
        //===========================================================================
        async confirmActivationCaisse() {
            try {
                const caisseData = {
                    codeActe: `${this.newService.code} - CAISSE`,
                    rppsItem: [this.newService.numero],
                    value: this.newService.valeur,
                };

                // Dispatch de l'action Vuex pour ajouter le RPPS
                await this.$store.dispatch("rpps/activateFeature", caisseData);

                // Réinitialisation du formulaire et actualisation des données affichées
                this.newService = { code: "", numero: "", valeur: "" };
                await this.fetchAllCaisseData();
                this.search();
                this.showAddForm = false;
                this.showActivationDialogCaisse = false;
            } catch (error) {
                throw new Error("Erreur lors de l'ajout des données de caisse:", error);
            }
        },

        //===========================================================================
        // DELETE CAISSE
        //===========================================================================
        supprimerCaisseData(item) {
            this.itemToDelete = item;
            this.state = "deletingCaisse";
        },


        //===========================================================================
        // CANCEL DELETE
        //===========================================================================
        cancelDelete() {
            this.state = "cancellingDelete";
        },

        //===========================================================================
        // DELETE CONFIRMATION
        //===========================================================================
        async confirmDelete() {
            try {
                if (this.state === "deletingCaisse") {
                    const res = await this.$store.dispatch("rpps/serviceDelete", {
                        caisseCode: this.itemToDelete.numeroCaisse,
                        item: this.itemToDelete.code,
                    });
                    if (res && res.status == 200) {
                        await this.fetchAllCaisseData();
                        this.search();
                    }
                }
                this.state = "idle";
                this.itemToDelete = null;
            } catch (error) {
                throw new Error("Erreur lors de la suppression des données RPPS:", error);
            }
        },

        //===========================================================================
        // FONCTION DE RECHERCHE
        //===========================================================================
        search() {
            if (!this.query) {
                this.filteredDataArray = this.caisseDataArray;
                return;
            }
            this.filteredDataArray = this.caisseDataArray.filter(item => {
                return Object.values(item).some(val => {
                    if (String(val) && String(val).toLowerCase) {
                        return String(val).toLowerCase().includes(this.query.toLowerCase());
                    }
                    return false;
                });
            });
        },

        //===========================================================================
        // CLEAR SEARCH
        //===========================================================================
        clearSearch() {
            this.query = '';
            this.search();
        },

        //===========================================================================
        // CANCEL
        //===========================================================================
        cancel() {
            this.showAddForm = false;
            this.newService = {
                code: '',
                numero: '',
                valeur: '',
            };
        },

        //===========================================================================
        // VALIDATION FORMULAIRE
        //===========================================================================
        validateNumero() {
            const numPattern = /^\d{3}$/;
            if (!numPattern.test(this.newService.numero)) {
                this.newService.numero = '';
            }
        },
        validateValeur() {
            const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
            if (this.newService.valeur !== '0' && this.newService.valeur !== '1' && !datePattern.test(this.newService.valeur)) {
                this.newService.valeur = '';
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 48-57 are the keycodes for 0-9
                $event.preventDefault();
            }
        },
        formatDate(date) {
            let d = new Date(date);
            let day = ('0' + d.getDate()).slice(-2);
            let month = ('0' + (d.getMonth() + 1)).slice(-2);
            let year = d.getFullYear();

            this.newService.valeur = day + '/' + month + '/' + year;
        },
        openDatePicker() {
            this.$refs.datepicker.open();
        },
    },
};
</script>

<!-- eslint-disable prettier/prettier -->
<style scoped>
.v-radio .v-input--selection-controls__input {
    padding: 0;
}

.v-label {
    font-size: 14px !important;
    -webkit-text-fill-color: #495057 !important;
}

.margin-top {
    margin-top: 20px;
}

md-prokov .cancelButton {
    background-color: rgb(201, 85, 85) !important;
    color: white;
}

.datePicker {
    width: 200px;
    margin-top: 0;
    margin-bottom: 2rem;
}

.labelStyle {
    font-size: 1rem;
    margin-top: 1.3rem;
}
</style>