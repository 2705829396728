import { render, staticRenderFns } from "./TicketsType.vue?vue&type=template&id=0852241e&scoped=true"
import script from "./TicketsType.vue?vue&type=script&lang=js"
export * from "./TicketsType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0852241e",
  null
  
)

export default component.exports