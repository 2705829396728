<!--
@Author: Jessy DROUIN
@Date:   11-dec-2024
@Email:  jessy.drouin@equasens.com
@Project: MSSAFE LICENCES
@Filename: MSSafe.vue
@Last modified by:   Jessy
@Last modified time: 11-dec-2024
-->

<!--
//===========================================================================
// TEMPLATE
//===========================================================================
-->
<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-100">
        <!-- Barre de recherche -->
        <md-card style="margin-bottom: 40px; margin-top: 10px">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Recherche</label>
                <md-input v-model="query" v-on:keyup.enter="search"></md-input>
              </md-field>
              <button class="diagCloseButton" @click="clearSearch()">
                <md-icon>delete</md-icon>
              </button>
            </div>
          </div>
          <md-button class="md-prokov researcheButton" @click="search()"
            >Rechercher</md-button
          >
        </md-card>

        <!-- Bouton d'ajout de service -->
        <md-card style="margin-bottom: 40px; margin-top: 10px">
          <div class="md-layout md-alignment-top-left">
            <div class="md-layout-item">
              <md-button class="md-prokov addButton" @click="showAddForm = true"
                >Ajouter une licence MSSAFE</md-button
              >
            </div>
          </div>

          <!-- Licence Form -->
          <div v-if="showAddForm" class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Licence ID (5 chiffres)</label>
                <md-input
                  v-model="newLicence.licenceID"
                  @input="validateLicenceID"
                  :class="{ 'error-text': licenceIDError }"
                />
              </md-field>

              <!-- Licence Check -->
              <p v-if="licenceIDError" class="error-message">
                {{ licenceCheckError }}
              </p>

              <p v-if="showValidation">
                La licence ID {{ newLicence.licenceID }} existe.
              </p>

              <!-- Section Date -->
              <v-row class="mt-3">
                <v-col cols="12" class="mb-3 d-flex align-items-center">
                  <label class="mr-3 labelStyle">A échéance</label>
                  <md-datepicker
                    ref="datepicker"
                    class="datePicker"
                    @input="formatDate"
                  ></md-datepicker>
                </v-col>
              </v-row>

              <!-- Bouton Ajouter -->
              <md-button
                class="md-prokov"
                @click="addService"
                :disabled="!isMSSafe"
              >
                Ajouter
              </md-button>
              <md-button class="md-prokov cancelButton" @click="cancel()"
                >Annuler</md-button
              >
            </div>
          </div>
        </md-card>

        <!-- Tableau d'affichage des données -->
        <h4 style="font-weight: bold">Licences MSSafe</h4>
        <v-data-table
          :headers="headers"
          :items="formattedDataArray"
          :footer-props="{ itemsPerPageOptions: [20, 50, -1] }"
          class="elevation-1"
        >
          <template v-slot:[`item.licenceID`]="{ item }">
            <p>{{ item.licenceID }}</p>
          </template>

          <template v-slot:[`item.customerGivenname`]="{ item }">
            <p>{{ item.customerGivenname }}</p>
          </template>

          <template v-slot:[`item.customerFamilyname`]="{ item }">
            <p>{{ item.customerFamilyname }}</p>
          </template>

          <template v-slot:[`item.expirationStatus`]="{ item }">
            <v-chip
              :color="item.expirationStatus === 'EN COURS' ? 'green' : 'red'"
              dark
            >
              {{ item.expirationStatus }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon small @click="deleteLicence(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>

        <!-- Dialogue de confirmation pour l'activation d'un service Caisse -->
        <v-dialog v-model="showActivationDialogMSSAFE" max-width="500px">
          <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <v-card-text
              >Voulez-vous vraiment activer ce service MSSAFE ?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="updateLicenceAbonnements"
                >Oui</v-btn
              >
              <v-btn
                color="red darken-1"
                text
                @click="showActivationDialogMSSAFE = false"
                >Non</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialogue de confirmation pour suppression -->
        <v-dialog v-model="showDeleteConfirm" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Supprimer une licence</v-card-title>
            <v-card-text
              >Voulez-vous vraiment supprimer cette licence MSSafe
              ?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="cancelDelete">Non</v-btn>
              <v-btn color="green darken-1" text @click="confirmDelete"
                >Oui</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<!--
//===========================================================================
// SCRIPT
//===========================================================================
-->
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MSSafe",
  data() {
    return {
      query: "",
      headers: [
        { text: "Licence ID", value: "licenceID" },
        { text: "Nom", value: "customerGivenname" },
        { text: "Prénom", value: "customerFamilyname" },
        { text: "Abonnements (Date)", value: "abonnementsDate" },
        { text: "Expiration", value: "expirationStatus" },
        { text: "Actions", value: "action", sortable: false },
      ],
      newLicence: {
        licenceID: "",
        abonnements: "{}",
      },
      filteredDataArray: [],
      mssafeOneData: [],
      licenceToDelete: null,
      showDeleteConfirm: false,
      showAddForm: false,
      isMSSafe: false,
      licenceIDError: false,
      licenceCheckError: null,
      showValidation: false,
      selectedDate: null,
      showActivationDialogMSSAFE: false,
    };
  },
  async created() {
    // Fetch des données à la création du composant
    await this.fetchMSSafe();
  },
  computed: {
    ...mapGetters("licences", ["getMSSafe", "getByOneMSSafe"]),

    //===========================================================================
    formattedDataArray() {
      return this.filteredDataArray.map((item) => {
        // Mémorisation abonnements et timestamp
        const abonnements = JSON.parse(item.abonnements || "{}");
        const msSafeTimestamp = abonnements.MSSAFE || null;

        // Formater la date
        const abonnementsDate = msSafeTimestamp
          ? new Date(msSafeTimestamp).toLocaleDateString("fr-FR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "Non défini";

        // Calculer l'état
        const now = Date.now();
        const expirationStatus = msSafeTimestamp
          ? msSafeTimestamp > now
            ? "EN COURS"
            : "EXPIRÉ"
          : "Non défini";

        // Retourne les items à jour
        return {
          ...item,
          abonnementsDate,
          expirationStatus,
        };
      });
    },
  },
  watch: {
    // Mettre à jour la table si les données changent
    getMSSafe(newData) {
      this.filteredDataArray = newData || [];
    },
    getByOneMSSafe(newData) {
      this.mssafeOneData = newData || [];
    },
  },
  methods: {
    ...mapActions("licences", [
      "fetchMSSafe",
      "deleteLicenceMSSafe",
      "updateMSSafeAbonnements",
      "fetchByOneMSSafe",
      "deleteMSSafeAbonnements",
    ]),

    //===========================================================================
    // SEARCH
    // Filtre des données dans le champ de recherche
    //===========================================================================
    search() {
      if (!this.query) {
        // Récupération des données dans le tableau
        this.filteredDataArray = this.getMSSafe || [];
        return;
      }

      // Filtrage des données
      this.filteredDataArray = this.getMSSafe.filter((item) => {
        return (
          String(item.licenceID)
            .toLowerCase()
            .includes(this.query.toLowerCase()) ||
          String(item.customerGivenname) ||
          String(item.customerFamilyname) ||
          String(item.abonnements)
            .toLowerCase()
            .includes(this.query.toLowerCase())
        );
      });
    },

    //===========================================================================
    // CLEAR SEARCH
    // Remise à zero du champ de recherche
    //===========================================================================
    clearSearch() {
      this.query = "";
      this.search();
    },

    //===========================================================================
    // DELETE LICENCE
    // Affichage du dialogue de suppression de licence
    //===========================================================================
    deleteLicence(item) {
      this.licenceToDelete = item;
      this.showDeleteConfirm = true;
    },

    //===========================================================================
    // CANCEL DELETE
    // Annulation du dialogue de suppression
    //===========================================================================
    cancelDelete() {
      this.licenceToDelete = null;
      this.showDeleteConfirm = false;
    },

    //===========================================================================
    // ADD SERVICE
    // Affichage du dialogue d'ajout de licence
    //===========================================================================
    async addService() {
      this.showActivationDialogMSSAFE = true;
    },

    //===========================================================================
    // CONFIRM DELETE
    // Processus de suppression (retrait de la clé "MSSAFE", sans supprimer le reste)
    //===========================================================================
    async confirmDelete() {
      try {
        if (this.licenceToDelete) {
          // Mémorisation
          const licenceID = this.licenceToDelete.licenceID;

          // Récupérer les abonnements actuels
          let abonnements = this.licenceToDelete.abonnements;

          // Convertir en objet s'il est sous forme de chaîne JSON
          if (typeof abonnements === "string") {
            abonnements = JSON.parse(abonnements);
          }

          // Supprimer la clé `MSSAFE`
          delete abonnements.MSSAFE;

          // Convertir les abonnements en JSON
          const abonnementsJSON = JSON.stringify(abonnements);

          // Mettre à jour les abonnements
          await this.deleteMSSafeAbonnements({
            licenceID,
            abonnements: abonnementsJSON,
            operation: "delete",
          });

          // Recharger les données après la suppression
          await this.fetchMSSafe();
          this.search();
        }
      } catch (error) {
        throw new Error(
          "Erreur lors de la suppression de la licence MSSafe :",
          error
        );
      } finally {
        // Suppression du dialogue
        this.cancelDelete();
      }
    },

    //===========================================================================
    // FORMAT DATE
    // Processus de conversion "Date Picker ==> Timestamp"
    //===========================================================================
    formatDate(date) {
      // Init
      let formattedDate;

      // Vérification si la date est une chaîne au format DD/MM/YYYY
      if (typeof date === "string") {
        const [day, month, year] = date
          .split("/")
          .map((part) => parseInt(part, 10));

        if (
          !day ||
          !month ||
          !year ||
          isNaN(day) ||
          isNaN(month) ||
          isNaN(year)
        ) {
          throw new Error(
            "Date invalide, veuillez utiliser le format DD/MM/YYYY."
          );
        }

        // Création de la nouvelle date
        formattedDate = new Date(year, month - 1, day, 0, 0, 0, 0);

        // Check de l'objet Date
        if (isNaN(formattedDate.getTime())) {
          throw new Error("Erreur lors de la création de l'objet Date.");
        }
      } else if (date instanceof Date) {
        // Mémorisation
        formattedDate = date;

        // Forcer l'heure à 00:00:00
        formattedDate.setHours(0, 0, 0, 0);

        // Check de l'objet Date
        if (isNaN(formattedDate.getTime())) {
          throw new Error("Date invalide.");
        }
      } else {
        throw new Error("Le format de la date est non pris en charge.");
      }

      // Création d'un timestamp
      const timestamp = formattedDate.getTime();

      // Vérification si le timestamp a changé
      if (this.selectedDate === timestamp) {
        return;
      }

      // Enregistrement du nouveau timestamp
      this.selectedDate = timestamp;

      return this.selectedDate;
    },

    //===========================================================================
    // VALIDATE LICENCE ID
    // Processus de vérification d'une licenceID en base
    // Si la licence existe, alors le bouton de création s'active
    //===========================================================================
    async validateLicenceID() {
      // Mémorisation
      let licenceID = this.newLicence.licenceID;

      // Test sur 5 digits
      const isFiveDigits = /^\d{5}$/.test(licenceID);

      // Affichage du message d'erreur tant que les 5 digits ne sont pas entrées
      if (!isFiveDigits) {
        this.licenceIDError =
          "Le Licence ID doit contenir exactement 5 chiffres.";
        this.isMSSafe = false;
        this.showValidation = false;
        return;
      }

      // Ajoutez le zéro devant le licenceID
      licenceID = "0" + licenceID;

      // Appel Vuex pour fetch la donnée unique de la licenceID
      await this.fetchByOneMSSafe({ licenceID });

      // Vérification de la valeur dans `getByOneMSSafe`
      const mssafeOneData = this.getByOneMSSafe;

      if (!mssafeOneData) {
        this.licenceIDError =
          "Le Licence ID n'existe pas dans la base de données.";
        this.isMSSafe = false;
        this.showValidation = false;
        return;
      }

      // Vérification de la correspondance
      const licenceExists = mssafeOneData === licenceID;

      if (!licenceExists) {
        this.licenceIDError =
          "Le Licence ID n'existe pas dans la base de données.";
        this.isMSSafe = false;
        this.showValidation = false;
        return;
      }

      // Si tout est validé
      this.licenceIDError = "";
      this.isMSSafe = true;
      this.showValidation = true;
    },

    //===========================================================================
    // UPDATE LICENCE ABONNEMENTS
    // Processus de modification d'une licenceID en base (PUT)
    //===========================================================================
    async updateLicenceAbonnements() {
      // Sorti prématuré si aucune donnée
      if (!this.isMSSafe) {
        return;
      }

      try {
        // Mémorisation
        const licenceID = this.newLicence.licenceID;

        // Vérifie et met à jour les abonnements avec la date sélectionnée dans le date picker
        let abonnementsObj = this.newLicence.abonnements
          ? JSON.parse(this.newLicence.abonnements)
          : {};

        if (this.selectedDate) {
          // Ajoute ou met à jour l'entrée MSSAFE avec le timestamp récupéré
          abonnementsObj["MSSAFE"] = this.selectedDate;

          // Converti les abonnements en JSON
          const abonnementsJSON = JSON.stringify(abonnementsObj);

          // Appelle l'action Vuex pour mettre à jour les abonnements
          await this.updateMSSafeAbonnements({
            licenceID,
            abonnements: abonnementsJSON,
            operation: "add", // Token d'identification pour l'endpoint PUT
          });

          // Clean
          this.cancel();
          this.fetchMSSafe();
          this.search();
        } else {
          throw new Error("Le timestamp est invalide.", this.selectedDate);
        }
      } catch (error) {
        throw new Error("Erreur lors de l'ajout de la licence MSSAFE :", error);
      }
    },

    //===========================================================================
    // CANCEL
    // Annulation / Remise à zeo
    //===========================================================================
    cancel() {
      this.newLicence = { licenceID: "", abonnements: "{}" };
      this.licenceIDError = "";
      this.isMSSafe = false;
      this.showValidation = false;
      this.showAddForm = false;
      this.showActivationDialogMSSAFE = false;
    },
  },
};
</script>

<!--
//===========================================================================
// STYLE
//===========================================================================
-->
<style scoped>
.v-radio .v-input--selection-controls__input {
  padding: 0;
}

.v-label {
  font-size: 14px !important;
  -webkit-text-fill-color: #495057 !important;
}

.margin-top {
  margin-top: 20px;
}

.datePicker {
  width: 200px;
  margin-top: 0;
  margin-bottom: 2rem;
}

.labelStyle {
  font-size: 1rem;
  margin-top: 1.3rem;
}

.error-text {
  color: red; /* Change la couleur des caractères */
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}
</style>
