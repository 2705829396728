import { render, staticRenderFns } from "./TicketsOption.vue?vue&type=template&id=6d63d841&scoped=true"
import script from "./TicketsOption.vue?vue&type=script&lang=js"
export * from "./TicketsOption.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d63d841",
  null
  
)

export default component.exports