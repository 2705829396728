var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-size-20"},[_c('span',{staticClass:"emailTicket"},[_vm._v(_vm._s(_vm.item.id)+" ")])]),_c('div',{staticClass:"md-layout-item md-medium-size-100 md-size-50"},[(
        _vm.item.PropFollowUp &&
        _vm.item.PropFollowUp.email &&
        _vm.item.PropFollowUp.email.length > 0
      )?_c('span',{staticClass:"emailTicket"},[(_vm.item.EeUser.givenname != 'Administrateur')?_c('span',[_vm._v("("+_vm._s(_vm.item.EeUser.givenname)+" "+_vm._s(_vm.item.EeUser.familyname)+")")]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.PropFollowUp.email)+" ")]):_vm._e(),(
        (!_vm.item.PropFollowUp ||
          !_vm.item.PropFollowUp.email ||
          _vm.item.PropFollowUp.email.length == 0) &&
        _vm.item.EeUser &&
        _vm.item.EeUser.email
      )?_c('span',{staticClass:"emailTicket"},[_vm._v("("+_vm._s(_vm.item.EeUser.givenname)+" "+_vm._s(_vm.item.EeUser.familyname)+") "+_vm._s(_vm.item.EeUser.email)+" ")]):_vm._e()]),_c('div',{staticClass:"md-layout-item md-medium-size-100 md-size-30"},[_c('div',{staticClass:"dateTicket"},[_vm._v(_vm._s(_vm.getDate(_vm.item.date)))])]),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('div',[_c('span',{staticClass:"colorTextProkov"},[_vm._v("Titre : ")]),_vm._v(_vm._s(_vm.item.sujet))])]),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-75"},[_c('div',{staticStyle:{"overflow":"scroll","max-height":"200px","white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.item.description)}})]),(
      (_vm.item.ref1 && _vm.item.ref1.length > 0) ||
      (_vm.item.ref2 && _vm.item.ref2.length > 0) ||
      (_vm.item.ref3 && _vm.item.ref3.length > 0)
    )?_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100",staticStyle:{"margin-top":"20px"}}):_vm._e(),(_vm.item.ref1 && _vm.item.ref1.length > 0)?_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"},[_c('div',[_c('span',{staticClass:"colorTextProkov"},[_vm._v("Référence 1 : ")]),_vm._v(_vm._s(_vm.item.ref1)+" ")])]):_vm._e(),(_vm.item.ref2 && _vm.item.ref2.length > 0)?_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"},[_c('div',[_c('span',{staticClass:"colorTextProkov"},[_vm._v("Référence 2 : ")]),_vm._v(" "+_vm._s(_vm.item.ref2)+" ")])]):_vm._e(),(_vm.item.ref3 && _vm.item.ref3.length > 0)?_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"},[_c('div',[_c('span',{staticClass:"colorTextProkov"},[_vm._v("Référence 3 : ")]),_vm._v(" "+_vm._s(_vm.item.ref3)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }