import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

const state = {
  loading: false,
  error: {},
  subscriptions: [],
  allSubscriptions: [],
  status: "",
};

const getters = {
  getSubscriptions(context) {
    return context.subscriptions;
  },
  getAllSubscriptions(context) {
    return context.allSubscriptions;
  },
  getStatus(context) {
    return context.status;
  },
};

const mutations = {
  UPDATE_SUBSCRIPTIONS(state, payload) {
    state.subscriptions = payload;
    state.allSubscriptions = payload;
  },
  FILTER_SUBSCRIPTIONS(state, payload) {
    if (payload.eeUserId) {
      state.subscriptions = payload.subscriptions.filter(
        (v) => v.eeUserId == payload.eeUserId
      );
    } else {
      state.subscriptions = payload.subscriptions;
    }
  },
  UPDATE_LOADING(state, enabled) {
    state.loading = enabled;
  },
  UPDATE_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  async activateSubscription(context, param) {
    context.commit("UPDATE_LOADING", true);
    context.commit("UPDATE_STATUS", "");
    let res = await axios({
      method: "PUT",
      url: server.url + server.urls.subscriptions,
      headers: server.getHeader(),
      data: { ...param },
    });
    context.commit("UPDATE_STATUS", res.data.success ? "✓" : "✖︎");
    context.commit("UPDATE_LOADING", false);
  },
  async filterSubscriptions(context, param) {
    context.commit("FILTER_SUBSCRIPTIONS", {
      eeUserId: param.eeUserId,
      subscriptions: context.getters.getAllSubscriptions,
    });
  },
  async resetStatus(context) {
    context.commit("UPDATE_STATUS", "");
  },
  async getSubscriptions(context) {
    context.commit("UPDATE_STATUS", "");
    context.commit("UPDATE_LOADING", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.subscriptions,
        headers: server.getHeader(),
      });
      let subscriptions = res?.data?.data?.subscriptions ?? [];
      subscriptions = subscriptions.map((v) => {
        v.expirationDateSorting = v.expirationDate ?? "0";
        return v;
      });
      context.commit("UPDATE_SUBSCRIPTIONS", subscriptions);
    } catch (error) {
      // console.log(error);
    }
    context.commit("UPDATE_LOADING", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
