/**
 * @Author: Florian Merel <florian>
 * @Date:   16-Dec-2020
 * @Email:  florian.merel@prokov.com
 * @Project: ComEditServer
 * @Filename: activity.js
 * @Last modified by:   florian
 * @Last modified time: 15-Jul-2021
 */

import axios from "axios";
import server from "../server.js";
import servicesError from "../ServicesError.js";

//===========================================================================
// STATE
//===========================================================================

const state = {
  loading: false,
  error: {},
  data: {},
  licence: {},
  target: null,
  blackList: {},
  report: {},
  licenceUser: [],
  mssafeData: [],
  mssafeOneData: null,
};

//===========================================================================
// GETTERS
//===========================================================================
const getters = {
  getData(context) {
    return context.data;
  },

  getLicence(context) {
    return context.licence;
  },

  getLicenceUser(state) {
    return state.licenceUser;
  },

  getTarget(context) {
    return context.target;
  },

  getMSSafe(state) {
    return state.mssafeData;
  },

  getByOneMSSafe(state) {
    return state.mssafeOneData;
  },

  getLicenceById: (state) => (licenceID) => {
    return state.licences.find((licence) => licence.licenceID === licenceID);
  },

  getReportData(context) {
    return context.report;
  },

  getBlackListData(context) {
    return context.blackList;
  },
};

//===========================================================================
// MUTATIONS
//===========================================================================
const mutations = {
  setResponse(context, data) {
    context.error = null;
    context.data = data;
  },

  setError(context, error) {
    context.data = null;
    context.error = error;
  },

  setLoading(context, loading) {
    context.loading = loading;
  },

  setData(context, data) {
    context.data = data;
  },

  setLicence(context, data) {
    context.licence = data;
  },

  setTargetLicence(context, data) {
    context.target = data;
  },

  setBlackList(context, data) {
    context.blackList = data;
  },

  setReport(context, data) {
    context.report = data;
  },

  setMSSafe(state, data) {
    state.mssafeData = data;
  },

  setByOneMSSafe(state, data) {
    state.mssafeOneData = data;
  },

  setLicenceUser(context, data) {
    context.licenceUser = data;
  },

  UPDATE_LICENCE(state, updatedLicence) {
    const index = state.licences.findIndex(
      (licence) => licence.licenceID === updatedLicence.licenceID
    );
    if (index !== -1) {
      state.licences.splice(index, 1, updatedLicence);
    }
  },
};

//===========================================================================
// ACTIONS
//===========================================================================

const actions = {
  //===========================================================================
  async getLicences(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.licences + params,
        headers: server.getHeader(),
      });
      context.commit("setResponse", res);
      context.commit("setData", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async getLicence(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.licence + params,
        headers: server.getHeader(),
      });
      //context.commit("setResponse", res);
      context.commit("setLicence", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async getLicenceUser(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + "/licenceUser/" + params,
        headers: server.getHeader(),
      });
      context.commit("setLicenceUser", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async getBlackList(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.licence + "/" + id + "/blackList",
        headers: server.getHeader(),
      });

      context.commit("setBlackList", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async getReport(context, id) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.licence + "/" + id + "/report",
        headers: server.getHeader(),
      });

      context.commit("setReport", res.data);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async removeBlackList(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "DELETE",
        url: server.url + server.urls.licence + "/" + params.id + "/blackList",
        headers: server.getHeader(),
        data: params.data,
      });
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        context.commit("setError", error.response.data);
      }
    }
    context.commit("setLoading", false);
  },

  //===========================================================================
  async pingLicence(context, params) {
    context.commit("setLoading", true);
    try {
      let res = await axios({
        method: "GET",
        url: server.url + server.urls.pingLicence + params,
        headers: server.getHeader(),
      });

      if (res && res.data && res.data.data && res.data.data.ping) {
        return true;
      }
      return false;
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
      }
    }
    return false;
  },

  //===========================================================================
  async fetchMSSafe({ commit }) {
    commit("setLoading", true);
    try {
      const res = await axios({
        method: "GET",
        url: `${server.url}${server.urls.licences}${server.urls.mssafe}`,
        headers: server.getHeader(),
      });
      commit("setMSSafe", res.data.licences_mssafe);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        commit("setError", error.response.data);
      }
    } finally {
      commit("setLoading", false);
    }
  },

  //===========================================================================
  async fetchByOneMSSafe({ commit }, { licenceID }) {
    commit("setLoading", true);
    try {
      const res = await axios({
        method: "GET",
        url: `${server.url}${server.urls.licence}${"/" + licenceID}${"/MS"}`,
        headers: server.getHeader(),
      });
      commit("setByOneMSSafe", res.data.data.id);
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response.data);
        commit("setError", error.response.data);
      }
    } finally {
      commit("setLoading", false);
    }
  },

  //===========================================================================
  async updateMSSafeAbonnements(
    { commit },
    { licenceID, abonnements, operation }
  ) {
    commit("setLoading", true);
    licenceID = "0" + licenceID; // Correspond aux ID en base
    try {
      const res = await axios({
        method: "PUT",
        url: `${server.url}${server.urls.licences}${server.urls.mssafe}${
          "/" + licenceID
        }`,
        headers: server.getHeader(),
        data: {
          licenceID,
          abonnements,
          operation,
        },
      });

      if (res && res.data && res.data.data && res.data.data.message) {
        commit("setResponse", res.data.data.message);
      } else {
        throw new Error("Réponse inattendue:", res);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response?.data);
        commit("setError", error.response?.data);
      }
    } finally {
      commit("setLoading", false);
    }
  },

  //===========================================================================
  async deleteMSSafeAbonnements(
    { commit },
    { licenceID, abonnements, operation }
  ) {
    commit("setLoading", true);
    try {
      const res = await axios({
        method: "PUT",
        url: `${server.url}${server.urls.licences}${server.urls.mssafe}${
          "/" + licenceID
        }`,
        headers: server.getHeader(),
        data: {
          licenceID,
          abonnements,
          operation,
        },
      });

      if (res && res.data && res.data.data && res.data.data.message) {
        commit("setResponse", res.data.data.message);
      } else {
        throw new Error("Réponse inattendue:", res);
      }
    } catch (error) {
      if (servicesError.isConnection(error)) {
        servicesError.analyseErrorService(error.response?.data);
        commit("setError", error.response?.data);
      }
    } finally {
      commit("setLoading", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
