<script src="https://cdn.tailwindcss.com"></script>
<template>
  <div class="content">
    <v-card class="p-4" v-if="!selectedItem" elevation="0" outlined>
      <div class="flex flex-row items-center justify-between">
        <div class="text-xl font-medium">
          Total HT : {{ totalPrice }}€ pour {{ filteredItems.length }} items
        </div>
        <v-btn color="blue-grey" class="white--text" @click="downloadCSV()">
          Exporter
          <v-icon right dark> mdi-file-export </v-icon>
        </v-btn>
      </div>

      <div class="relative mx-auto my-6">
        <div
          class="
            absolute
            -top-3
            left-1/2
            transform
            -translate-x-1/2
            bg-white
            px-4
          "
        >
          <div class="text-slate-400 text-lg font-normal">Filtres</div>
        </div>
        <div class="border-2 border-grey rounded-lg p-2">
          <div class="flex flex-row gap-4 items-center">
            <div class="flex-1">
              <div>
                <v-select
                  :items="existingStates"
                  v-model="selectedState"
                ></v-select>
                <div class="text-slate-400 -mt-4 mb-2">Etat</div>
              </div>
            </div>
            <div class="flex-1">
              <div>
                <v-select
                  :items="existingBillingModes"
                  v-model="selectedBillingMode"
                ></v-select>
                <div class="text-slate-400 -mt-4 mb-2">Facturation</div>
              </div>
            </div>
            <div class="flex-1">
              <md-datepicker v-model="startDate" />
              <div class="text-slate-400 -mt-5 mb-4">Date de début</div>
            </div>
            <div class="flex-1">
              <md-datepicker class="flex-1" v-model="endDate" />
              <div class="text-slate-400 -mt-5 mb-4">Date de fin</div>
            </div>
            <div class="flex-1">
              <md-field md-clearable class="md-toolbar-section-end">
                <md-input placeholder="Recherche..." v-model="search" />
              </md-field>
              <div class="text-slate-400 -mt-5 mb-4">Mots-clés</div>
            </div>
          </div>
        </div>
      </div>

      <md-table
        v-if="!selectedItem"
        v-model="filteredItems"
        md-sort="updatedDate"
        md-sort-order="asc"
        @md-selected="onCellPress"
        md-card
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
        >
          <md-table-cell md-sort-by="statusLabel" md-label="Etat">
            <div
              v-if="item.status == 'DRAFT'"
              class="
                text-slate-500
                border-slate-500 border-solid
                rounded-full
                text-center
                px-4
                py-1
              "
              style="border-width: 1px"
            >
              {{ item.statusLabel }}
            </div>
            <div
              v-else-if="item.status == 'SENT'"
              class="
                text-blue-500
                border-blue-500 border-solid
                rounded-full
                text-center
                px-4
                py-1
              "
              style="border-width: 1px"
            >
              {{ item.statusLabel }}
            </div>
            <div
              v-else-if="item.status == 'SIGNED'"
              class="
                text-center text-green-500
                border-green-500 border-solid
                rounded-full
                px-4
                py-1
              "
              style="border-width: 1px"
            >
              {{ item.statusLabel }}
            </div>
            <div
              v-else-if="item.status == 'NEW'"
              class="
                text-center text-red-500
                border-red-500 border-solid
                rounded-full
                px-4
                py-1
              "
              style="border-width: 1px"
            >
              {{ item.statusLabel }}
            </div>
          </md-table-cell>
          <md-table-cell md-sort-by="cabinetName" md-label="Cabinet médical">
            {{ item.cabinetName }}
          </md-table-cell>
          <md-table-cell md-label="Dept" md-sort-by="department">
            {{ item.department }}
          </md-table-cell>
          <md-table-cell md-label="Expert" md-sort-by="expertCompany">
            {{ item.expertCompany }}
          </md-table-cell>
          <md-table-cell md-label="Création" md-sort-by="createdAt">
            {{ new Date(item.createdAt).toLocaleDateString("fr") }}
          </md-table-cell>
          <md-table-cell md-label="Modif" md-sort-by="updatedAt">
            {{ new Date(item.updatedAt).toLocaleDateString("fr") }}
          </md-table-cell>
          <md-table-cell md-label="Capa" md-sort-by="deviceCount">
            {{ item.deviceCount }}
          </md-table-cell>
          <md-table-cell md-label="CPS" md-sort-by="situationCount">
            {{ item.situationCount }}
          </md-table-cell>
          <md-table-cell md-label="MSStory" md-sort-by="msStoryCount">
            {{ item.msStoryCount }}
          </md-table-cell>
          <md-table-cell md-label="MSSafe" md-sort-by="updatedAt">
            {{ item.msSafeCount }}
          </md-table-cell>
          <md-table-cell md-label="HT" md-sort-by="amount">
            {{ item.amountWithoutVat ? item.amountWithoutVat + "€" : "" }}
          </md-table-cell>
          <md-table-cell md-label="TTC" md-sort-by="amount">
            {{ item.amountWithVat ? item.amountWithVat + "€" : "" }}
          </md-table-cell>
          <md-table-cell md-label="Récu." md-sort-by="recurrence">
            {{ item.recurrence ? item.recurrence : "" }}
          </md-table-cell>
          <md-table-cell md-label="Factu." md-sort-by="directBilling">
            <div v-if="item.status == 'DRAFT'"></div>
            <div
              v-else-if="item.directBilling"
              class="
                text-center text-pink-500
                border-pink-500 border-solid
                rounded-full
                px-2
                py-1
              "
              style="border-width: 1px"
            >
              D
            </div>
            <div
              v-else
              class="
                text-center text-slate-500
                border-slate-500 border-solid
                rounded-full
                px-2.5
                py-1
              "
              style="border-width: 1px"
            >
              I
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </v-card>

    <div v-if="selectedItem">
      <v-card elevation="0" outlined>
        <md-button class="md-success" @click="onBackPress()">
          <md-icon style="margin-right: 10px; margin-left: -20px">undo</md-icon>
          Retour
        </md-button>
        <v-card-title>SÉLECTION</v-card-title>
        <v-card-text>
          <div>Etat : {{ selectedItem.statusLabel }}</div>
          <div>
            Expert : {{ selectedItem.expertCompany }} ({{
              selectedItem.expertName
            }})
          </div>
          <div class="mb-4">
            Date de création :
            {{ new Date(selectedItem.createdAt).toLocaleDateString("fr") }}
          </div>
          <div class="flex flex-row">
            <div class="mr-6">
              <div class="font-bold my-2">Information Prospect</div>
              <div>
                Nom : {{ selectedItem.firstName }} {{ selectedItem.lastName }}
              </div>
              <div>Tel : {{ selectedItem.personalPhone }}</div>
              <div>Email : {{ selectedItem.personalEmail }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Information du Cabinet</div>
              <div>Nom facturation : {{ selectedItem.billingName }}</div>
              <div>Nom cabinet : {{ selectedItem.cabinetName }}</div>
              <div>RPPS : {{ selectedItem.rpps }}</div>
              <div>Activité : {{ selectedItem.activity }}</div>
              <div>Effectif : {{ selectedItem.headcount }}</div>
              <div>Logiciel actuel : {{ selectedItem.currentSoftware }}</div>
              <div>Tel : {{ selectedItem.officePhone }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Adresse</div>
              <div>Ville : {{ selectedItem.city }}</div>
              <div>Code postal : {{ selectedItem.zip }}</div>
              <div>Rue : {{ selectedItem.street }}</div>
              <div>Complément : {{ selectedItem.addressSupplement }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Licences MS3 actuelles</div>
              <div>{{ JSON.stringify(selectedItem.ms3Licenses, null, 2) }}</div>
              <div class="font-bold my-2">Commentaire</div>
              <div>{{ selectedItem.comment }}</div>
            </div>
            <div class="mr-6">
              <div class="font-bold my-2">Abonnement</div>
              <div>
                Nombre d'appareils connectés : {{ selectedItem.deviceCount }}
              </div>
              <div>
                Nombre de situations : {{ selectedItem.situationCount }}
              </div>
              <div>
                Mode d'utilisation :
                {{ selectedItem.multiMode == true ? "Multi" : "Mono" }}
              </div>
              <div>
                Montant HT
                {{ selectedItem.recurrence }} :
                {{
                  selectedItem.amountWithoutVat
                    ? selectedItem.amountWithoutVat + " €"
                    : ""
                }}
              </div>
              <div>
                Montant des FAS :
                {{
                  selectedItem.installationPrice
                    ? selectedItem.installationPrice + " €"
                    : ""
                }}
              </div>
              <div>
                Date prévisionnelle d'installation :
                {{ selectedItem.estimatedStartDate }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SubscriptionsOrders",
  data: function () {
    return {
      search: null,
      items: [],
      filteredItems: [],
      selectedItem: null,
      filteredItemsCount: 0,
      totalPrice: 0,
      searchText: "",
      startDate: null,
      endDate: null,
      existingStates: ["Tous", "Signé", "Envoyé", "Brouillon", "Erreur"],
      selectedState: "Tous",
      existingBillingModes: ["Tous", "Direct", "Indirect"],
      selectedBillingMode: "Tous",
    };
  },
  created: function () {
    this.$store.dispatch("orderform/getOrderForms");
  },
  watch: {
    orderForms: function (newVal, oldVal) {
      this.items = newVal.map((v) => {
        v.statusLabel = this.getLocalizedStatus(v.status);
        v.cabinetName =
          v.billingName?.length > 0
            ? v.billingName
            : [v.firstName, v.lastName].filter((v) => !!v).join(" ");
        v.expertCompany = v.prokOffreUserCompany;
        const expertName = [v.prokOffreUserFirstName, v.prokOffreUserLastName]
          .filter((v) => !!v)
          .join(" ");
        v.expertName = "";
        expertName?.length > 0 ? expertName : v.prokOffreUserEmail;
        v.department = v.zip?.slice(0, 2);
        v.amountWithoutVat =
          v.status != "DRAFT"
            ? this.formatPrice(v.totalAmountWithoutVat)
            : null;
        v.amountWithVat =
          v.status != "DRAFT"
            ? this.formatPrice(v.totalAmountWithoutVat * 1.2)
            : null;
        v.recurrence =
          v.status != "DRAFT"
            ? v.recurrence == "M"
              ? "mensuel"
              : "annuel"
            : null;
        return v;
      });
      this.filteredItems = this.items;
      this.computeFiltering();
    },
    search: function (newVal, oldVal) {
      this.searchText = newVal.toLowerCase();
      this.computeFiltering();
    },
    startDate: function (newVal, oldVal) {
      this.computeFiltering();
    },
    endDate: function (newVal, oldVal) {
      this.computeFiltering();
    },
    selectedState: function (newVal, oldVal) {
      this.computeFiltering();
    },
    selectedBillingMode: function (newVal, oldVal) {
      console.log("selectedBillingMode", newVal);
      this.computeFiltering();
    },
  },
  computed: {
    ...mapState("orderform", ["orderForms"]),
  },
  methods: {
    computeFiltering() {
      const searchKeys = [
        "cabinetName",
        "expertName",
        "expertCompany",
        "amount",
        "department",
        "createdAt",
        "updatedAt",
      ];
      this.filteredItems = this.items
        .filter((item) =>
          this.searchText?.length == 0
            ? true
            : searchKeys
                .map((key) => String(item[key]).toLowerCase())
                .map((val) => val.includes(this.searchText))
                ?.includes(true)
        )
        .filter((item) => {
          switch (this.selectedState) {
            case "Signé":
              return item.statusLabel == this.getLocalizedStatus("SIGNED");
              break;
            case "Envoyé":
              return item.statusLabel == this.getLocalizedStatus("SENT");
              break;
            case "Brouillon":
              return item.statusLabel == this.getLocalizedStatus("DRAFT");
              break;
            case "Erreur":
              return item.statusLabel == this.getLocalizedStatus("NEW");
              break;
            default:
              return true;
          }
        })
        .filter((item) =>
          this.startDate ? new Date(item.createdAt) >= this.startDate : true
        )
        .filter((item) =>
          this.endDate ? new Date(item.createdAt) <= this.endDate : true
        )
        .filter((item) => {
          switch (this.selectedBillingMode) {
            case "Direct":
              return item.directBilling == true;
              break;
            case "Indirect":
              return item.directBilling == false;
              break;
            default:
              return true;
          }
        });
      this.filteredItemsCount = this.filteredItems.length;
      this.totalPrice = this.formatPrice(
        this.filteredItems.reduce(
          (acc, item) =>
            acc +
            (item.amountWithoutVat != null
              ? Number(item.amountWithoutVat.replace(",", "."))
              : 0),
          0
        )
      );
    },
    onBackPress() {
      this.selectedItem = null;
    },
    onCellPress(item) {
      this.selectedItem = item;
    },
    getLocalizedStatus(status) {
      const statusList = {
        DRAFT: "Brouillon",
        SENT: "Envoyé",
        SIGNED: "Signé",
        NEW: "Erreur",
      };
      return statusList[status];
    },
    downloadCSV() {
      const separator = ",";
      var csv =
        "Etat, Cabinet médical, Dept, Expert, Creation, Capa, CPS, MSStory, MSSafe, Montant HT, Récurrence, Facturation\n";
      this.filteredItems.forEach(function (item) {
        csv +=
          [
            item.statusLabel ?? "",
            item.cabinetName ?? "",
            item.department ?? "",
            item.expertCompany ?? "",
            new Date(item.creationDate).toLocaleDateString("fr"),
            item.deviceCount ?? "",
            item.situationCount ?? "",
            item.msStoryCount ?? "",
            item.msSafeCount ?? "",
            item.amountWithoutVat ?? "",
            item.recurrence ?? "",
            item.directBilling ? "D" : "I",
          ]
            .map((v) => (v ? `"${v}"` : ""))
            .join(separator) + "\n";
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "export.csv";
      hiddenElement.click();
    },
    formatPrice(price) {
      let fixedPrice = price?.toFixed(2).replace(".00", "").replace(".", ",");
      return fixedPrice ?? 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
