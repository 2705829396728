<!--
@Author: Florian Merel <florian>
@Date:   24-Aug-2021
@Email:  florian.merel@prokov.com
@Project: ComEditServer
@Filename: Tickets_Data.vue
@Last modified by:   florian
@Last modified time: 25-Aug-2021
-->

<template>
  <div class="md-layout" v-if="getTicket != null">
    <div
      class="md-layout-item md-medium-size-100 md-size-50"
      style="margin-top: 15px"
    >
      <span class="md-title" style="color: #e2007a">{{ getTicket.id }}</span>
      <span class="md-title" style=""> - {{ getTicket.sujet }}</span>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-50"
      style="margin-top: 3px"
    >
      <v-chip
        v-if="getTicket.ticketFollowUp && getTicket.ticketFollowUp.type"
        style="float: right"
        class="ma-2"
        :color="getChipsTypeColor(getTicket.ticketFollowUp.type)"
        label
        text-color="white"
      >
        {{
          getTicket.ticketFollowUp.type == "Idée"
            ? "Suggestion"
            : getTicket.ticketFollowUp.type
        }}
      </v-chip>
      <v-chip
        style="float: right"
        class="ma-2"
        :color="getChipsFolderColor(getTicket)"
        label
        text-color="white"
      >
        {{ getChipsFolder(getTicket) }}
      </v-chip>
    </div>

    <div class="md-layout-item md-medium-size-100 md-size-50">
      <span class="md-caption" style="">
        {{ getIdentity(getTicket) }} - {{ getMail(getTicket) }}</span
      >
    </div>

    <div class="md-layout-item md-medium-size-100 md-size-50">
      <span class="md-caption" style="float: right; margin-right: 10px">
        {{ getDate(getTicket.date) }}</span
      >
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      style="margin-top: 5px"
    >
      <md-divider></md-divider>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-size-100"
      style="margin-top: 10px"
    >
      <div style="white-space: pre-line" v-html="getTicket.description"></div>
      <br />
      <div>{{ getTicket.ref1 }}</div>
      <div>{{ getTicket.ref2 }}</div>
      <div>{{ getTicket.ref3 }}</div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Vue from "vue";
import toolsFormat from "@/tools/toolsFormat.js";
import Tickets_Tools from "../Tickets_Tools.js";

export default {
  name: "tickets-data",
  data() {
    return {};
  },
  methods: {
    getDate(date) {
      return toolsFormat.getDateLong(date);
    },

    // ------ FOLDER ------
    getChipsFolder(ticket) {
      return toolsFormat.getChipsFolder(ticket);
    },

    getChipsFolderColor(ticket) {
      return toolsFormat.getChipsFolderColor(ticket);
    },

    // ----- Identité -----
    getIdentity(ticket) {
      return Tickets_Tools.getIdentity(ticket);
    },

    getMail(ticket) {
      return Tickets_Tools.getMail(ticket);
    },

    // ------ TYPE ------
    getChipsTypeColor(type) {
      return toolsFormat.getChipsTypeColor(type);
    },
  },
  computed: {
    getTicket() {
      var obj = store.getters["ticketsV2Filters/getSelectTickets"];
      return obj;
    },
  },
  watch: {
    $route(to, from) {},
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
