<script src="https://cdn.tailwindcss.com"></script>
<template>
  <div class="content">
    <v-card>
      <v-tabs v-model="currentTabIndex" background-color="#e2007a" dark>
        <v-tab v-for="tab in tabs" :key="tab" :value="tab">{{ tab }}</v-tab>
      </v-tabs>
    </v-card>
    <!-- <SubscriptionsStats v-if="currentTabIndex === 0"></SubscriptionsStats> -->
    <SubscriptionsOrders v-if="currentTabIndex === 0"></SubscriptionsOrders>
    <!-- <SubscriptionsPrices v-if="currentTabIndex === 1"></SubscriptionsPrices> -->
  </div>
</template>

<script>
import Vue from "vue";
import SubscriptionsPrices from "./SubscriptionsPrices.vue";
import SubscriptionsStats from "./SubscriptionsStats.vue";
import SubscriptionsOrders from "./SubscriptionsOrders.vue";

export default {
  name: "Subscriptions",
  components: {
    SubscriptionsStats,
    SubscriptionsOrders,
    SubscriptionsPrices,
  },
  data: function () {
    return {
      tabs: ["Commandes"],
      currentTabIndex: 0,
    };
  },
  created: function () {},
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
