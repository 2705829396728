<template>
  <div class="content">
    <v-card>
      <v-tabs v-model="currentTabIndex" background-color="#e2007a" dark>
        <v-tab v-for="tab in tabs" :key="tab" :value="tab">{{ tab }}</v-tab>
      </v-tabs>
    </v-card>
    <div v-if="currentTabIndex == 1">
      <h4 class="pt-4 font-bold">Activation Loquii</h4>
      <md-field>
        <label>Utilisateur</label>
        <md-input v-model="query"></md-input>
        <span class="text-pink-500">{{ user?.name }}</span>
      </md-field>
      <div>
        <md-radio v-model="subscriptionRadio" :value="true"
          >Abonnement</md-radio
        >
        <md-radio v-model="subscriptionRadio" :value="false"
          >Période d'essai</md-radio
        >
      </div>
      <md-datepicker v-model="activationDate">
        <label>Date d'activation</label>
      </md-datepicker>
      <md-datepicker :hidden="subscriptionRadio" v-model="expirationDate">
        <label>Date d'expiration</label>
      </md-datepicker>
      <md-button
        :disabled="
          (subscriptionRadio == true &&
            (user == null || activationDate == null)) ||
          (subscriptionRadio == false &&
            (user == null || activationDate == null || expirationDate == null))
        "
        class="md-prokov"
        @click="submit()"
        >Valider {{ activationStatus }}</md-button
      >
    </div>
    <div v-if="currentTabIndex == 0" class="md-layout">
      <h4 class="pt-4 font-bold">Utilisateurs Loquii</h4>
      <md-field>
        <label>Recherche</label>
        <md-input v-model="query"></md-input>
        <span class="text-pink-500">{{ user?.name }}</span>
      </md-field>
      <md-table
        v-model="subscriptions"
        md-sort="activationDate"
        md-sort-order="asc"
        md-card
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          md-selectable="single"
        >
          <md-table-cell md-label="ID EE" md-sort-by="eeUserId">
            {{ item.eeUserId }}
          </md-table-cell>
          <md-table-cell md-label="Activation" md-sort-by="activationDate">
            {{ new Date(item.activationDate).toLocaleDateString("fr") }}
          </md-table-cell>
          <md-table-cell
            md-label="Expiration"
            md-sort-by="expirationDateSorting"
          >
            {{
              item.expirationDate
                ? new Date(item.expirationDate).toLocaleDateString("fr")
                : "Abonné"
            }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Loquii",
  data: () => ({
    tabs: ["Utilisateurs", "Activation"],
    currentTabIndex: 0,
    query: "",
    activationDate: new Date(),
    expirationDate: null,
    subscriptionRadio: true,
  }),
  created: function () {
    this.$store.dispatch("subscription/getSubscriptions");
  },
  watch: {
    activationStatus: function (newVal, oldVal) {
      if (newVal?.length == 0) {
        this.$store.dispatch("subscription/getSubscriptions");
      }
    },
    subscriptions: function (newVal, oldVal) {
      const sub = newVal?.[0];
      if (sub) {
        this.subscriptionRadio = sub.expirationDate == null;
        this.activationDate = sub?.activationDate
          ? new Date(sub.activationDate)
          : new Date();
        this.expirationDate = sub?.expirationDate
          ? new Date(sub.expirationDate)
          : null;
      } else {
        this.subscriptionRadio = true;
        this.activationDate = new Date();
        this.expirationDate = null;
      }
    },
    currentTabIndex: function (newVal, oldVal) {
      this.$store.dispatch("subscription/resetStatus");
      this.$store.dispatch("subscription/getSubscriptions");
    },
    subscriptionRadio: function (newVal, oldVal) {
      this.$store.dispatch("subscription/resetStatus");
      if (newVal == true) {
        this.expirationDate = null;
      }
    },
    query: function (newVal, oldVal) {
      this.searchUser(newVal);
    },
    loading: function (newVal, oldVal) {},
  },
  computed: {
    subscriptions: {
      get() {
        let list = this.$store.getters["subscription/getSubscriptions"];
        return list;
      },
      set(newValue) {},
    },
    activationStatus: {
      get() {
        return this.$store.getters["subscription/getStatus"];
      },
      set(newValue) {},
    },
    user: {
      get() {
        var res = this.$store.getters["usersList/getData"];
        let userLocal = null;
        if (res?.data?.users?.rows?.length > 0 && this.query?.length > 0) {
          const userFromStore = res?.data?.users?.rows?.[0];
          userLocal = {
            id: userFromStore.id,
            name: `${userFromStore.givenname} ${userFromStore.familyname} [${userFromStore.id}]`,
          };
        }
        this.$store.dispatch("subscription/filterSubscriptions", {
          eeUserId: userLocal?.id,
        });
        return userLocal;
      },
    },
  },
  methods: {
    submit() {
      const param = {
        userId: this.user.id,
        activationDate: this.activationDate,
        expirationDate: this.expirationDate,
        product: "LOQUII",
      };
      this.$store.dispatch("subscription/activateSubscription", param);
    },
    searchUser(searchTerm) {
      this.$store.dispatch("subscription/resetStatus");
      // Détermine si la recherche est entourée de guillemets pour une recherche spéciale
      if (searchTerm) {
        const isSpecialSearch =
          this.query.startsWith('"') && searchTerm.endsWith('"');
        let searchQuery = searchTerm;
        // Si la recherche est spéciale, garde les guillemets pour la requête
        if (!isSpecialSearch) {
          searchQuery = encodeURIComponent(searchTerm);
        } else {
          searchQuery = searchTerm;
        }
        const param =
          "?q=" + searchQuery + "&c=" + encodeURIComponent(this.cat);
        const path = "/users" + param;
        this.$store.dispatch("usersList/usersList", param);
      }
    },
  },
};
</script>
