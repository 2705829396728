import store from "@/store";

let url = process.env.VUE_APP_SERVER_URL;

let urls = {
  login: "/login",
  users: "/users",
  tickets: "/tickets",
  ticketsResponse: "/ticketsResponse",
  ticketsEnAttente: "/ticketsEnAttente",
  ticketsBadges: "/ticket/badges",
  ticketsFolders: "/ticket/folders",
  createdTicket: "/createdticket",
  usersUnlock: "/users/unlock",
  usersSecu: "/secu/users",
  usersInscription: "/usersinscription",
  usersReInscritpion: "/resendinscription",
  usersQuestions: "/userquestions",
  tokenIsValid: "/tokenIsValid",
  followup: "/followup",
  activity: "/activity",
  publicTicket: "/publicticket/v2",
  splitTicket: "/splitticket",
  uploadFiles: "/uploadfiles",
  downloadFiles: "/downloadfiles",
  licences: "/licences",
  licence: "/licence",
  pingLicence: "/pingLicence",
  server: "/server/status",
  analitics: "/analitics",
  admin: "/admin",
  providers: "/providers",
  statistiques: "/statistics",
  ticketV2filters: "/tickets/v2/filterdata",
  ticketV2filter: "/tickets/v2/filter",
  ticketV2badges: "/tickets/v2/badges",
  ticketV2keywords: "/tickets/v2/keywords",
  ticketV2Search: "/tickets/v2/search",
  ticketV2Tickets: "/tickets/v2",
  ticketV2Notifications: "/tickets/v2/notifications",
  syncAck: "/syncAck",
  logservices: "/logservices",
  segur: "/segur",
  orderforms: "/orderforms",
  subscriptions: "/subscriptions",
  licencesinscriptions: "/licencesinscriptions",
  mssafe: "/mssafe",
};

function getHeader() {
  let token = store.getters["authentification/getToken"];
  return { Authorization: "Bearer " + token };
}

export default {
  getHeader,
  urls,
  url,
};
